<template>
        <!-- Footer Start -->
        <footer class="main-footer bg-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 col-md-6">
                        <!-- About Footer Start -->
                        <div class="about-footer">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h2 class="fs-35">What you do today<br><span>can improve all your tomorrows.</span></h2>
                                <p class="text-white">- Ralph Martson</p>
                            </div>
                            <!-- Section Title End -->
                        </div>
                        <!-- About Footer End -->
                    </div>

                    <div class="col-lg-3 col-md-3">
                        <!-- Footer Contact Information Start -->
                        <div class="footer-contact-info">
                            <h3>Connect With Us</h3>
                        </div>
                        <!-- Footer Contact Information End -->

                        <!-- Footer Social Link Start -->
                        <div class="footer-social-links">
                            <ul>
                                <li><a href="https://www.linkedin.com/company/ingomu" target="_blank"><i class="fa-brands fa-linkedin" target="_blank"></i></a></li>
                                <li><a href="https://www.instagram.com/ingomu.coaching/" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                                <li><a href="https://www.youtube.com/@ingomu" target="_blank"><i class="fa-brands fa-youtube" target="_blank"></i></a></li>
                                <li><a href="https://www.facebook.com/ingomu.coaching/" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
                                <li><a href="https://www.pinterest.com/ingomu_coaching/" target="_blank"><i class="fa-brands fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                        <!-- Footer Social Link End -->
                    </div>

                    <div class="col-lg-2 col-md-3">
                        <!-- Footer Contact Information Start -->
                        <div class="footer-contact-info">
                            <h3>say hello</h3>
                            <p><a href="mailto:contact@ingomu.com?subject=Website%20Email%20Message" class="text-white">Contact@Ingomu.com</a></p>
                        </div>
                        <!-- Footer Contact Information End -->
                    </div>
                </div>

                <!-- Footer Copyright Section Start -->
                <div class="footer-copyright">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <!-- Footer Menu Start -->
                            <div class="footer-menu">
                                <ul>
                                    <li><router-link :to="{ name: 'home', hash: '#home' }" @click.native="scrollTo('home')">Home</router-link></li>
                                    <li><router-link :to="{ name: 'blogtags', params: { tag: 'Career opportunities' } }">Careers</router-link></li>
                                    <li><router-link :to="{ name: 'terms' }">Terms & Privacy</router-link></li>
                                    <li><router-link :to="{ name: 'contact' }">Contact</router-link></li>
                                    <li><a href="https://www.bonfire.com/store/ingomu/" target="_blank">Store</a></li>
                                </ul>
                            </div>
                            <!-- Footer Menu End -->
                        </div>

                        <div class="col-md-6">
                            <!-- Footer Copyright Start -->
                            <div class="footer-copyright-text">
                                <p>Copyright © {{ currentDate.getFullYear() }} Ingomu Learning LLC. All Rights Reserved.</p>
                            </div>
                            <!-- Footer Copyright End -->
                        </div>
                    </div>
                </div>
                <!-- Footer Copyright Section End -->
            </div>
        </footer>
        <!-- Footer End -->
</template>

<script>

export default {
    name: "InfineFooter",
    data() {
    return {
      currentDate: new Date
    }
  },
}
</script>
<style scoped>
.fs-25 {
    font-size: 35px;
}
</style>